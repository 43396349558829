<template>
  <div class="mr-4 md:mr-0">
    <trac-loading v-if="isLoading" />
    <main v-if="role.features">
      <trac-back-button>Existing Roles</trac-back-button>
      <div class="flex items-center mt-5 justify-between">
        <div>
          <h2 class="text-xs">Settings</h2>
          <h1 class="font-medium mt-2">Manage Roles</h1>
        </div>
        <div>
          <trac-button
            @button-clicked="sendRole"
            :disabled="
              role.role === '' || !role.features.find((f) => f.isSelected)
            "
          >
            <div class="px-4">Save</div>
          </trac-button>
        </div>
      </div>
      <div class="mt-10 flex justify-center items-center flex-col">
        <div class="max-w-2xl rounded white-gradient w-full">
          <div>
            <p class="py-5 pl-6 border-b text-sm text-accentDark">
              Role Detail
            </p>
          </div>
          <div class="pl-6 pb-8 pr-6 md:pr-0">
            <p class="pt-5 pb-5 text-xs text-accentDark">Role name</p>
            <trac-input
              v-model="role.role"
              class="max-w-sm w-full"
              placeholder="Enter Role Name Here"
            />
          </div>
        </div>
        <div class="w-full max-w-2xl mx-auto py-6">
          <trac-toggle :active="showStores" @newval="toggleShowStores"
            >Assign this role to specific location(s)</trac-toggle
          >
          <div class="mt-6 grid grid-cols-5 w-full" v-if="showStores">
            <div
              class="my-2"
              v-for="(store, index) in role.stores"
              :key="index"
            >
              <trac-checkbox
                @check="toggleStoreCheck($event, store)"
                :isChecked="store.isSelected"
              >
                <div class="text-xs ml-3 capitalize">{{ store.name }}</div>
              </trac-checkbox>
            </div>
          </div>
        </div>
        <div class="max-w-2xl rounded white-gradient w-full">
          <div>
            <p class="py-5 pl-6 border-b text-sm text-accentDark">
              Manage Permissions
            </p>
          </div>
          <div class="pl-6 pb-8">
            <p class="pt-5 pb-5 text-xs text-accentDark">
              Select from the list below, permissions you would like to allow
              for this role.
            </p>
            <div class="flex flex-col">
              <div class="pb-3" v-for="(feature, i) in role.features" :key="i">
                <trac-checkbox
                  @check="toggleCheck($event, feature)"
                  :isChecked="feature.isSelected"
                >
                  <div class="text-xs ml-3 capitalize">
                    {{ feature.Feature }}
                  </div>
                </trac-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <!-- <div class="shadow-white px-8 pt-8 pb-12 mt-10 rounded">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div class="text-sm">Selected roles</div>
          <div class="ml-2 text-sm font-medium">6/6</div>
        </div>
        <div>
          <trac-toggle
            :active="toggle"
            @newval="toggle = !$event"
            v-model="toggle"
          >
            <div class="uppercase text-primaryBlue text-xs font-medium">
              Assign To Store
            </div>
          </trac-toggle>
        </div>
      </div>
      <div
        class="mt-5 grid grid-cols-3 max-w-xl w-full bg-accentLight text-xs py-4 rounded pl-3"
      >
        <div class="text-accentDark">Manager</div>
        <div class="flex items-center">
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M13.2839 7.03354L10.906 4.65633L11.8576 3.70545C12.2812 3.28178 13.2516 3.19772 13.7593 3.70545L14.2348 4.18089C14.7425 4.68861 14.6591 5.659 14.2348 6.08333L13.2839 7.03354ZM12.333 7.9851L5.67561 14.6426L2.58496 15.3555L3.29777 12.2648L9.95518 5.60722L12.333 7.9851Z"
                fill="#253B95"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="13.7734"
                  height="18"
                  fill="white"
                  transform="translate(0.916016)"
                />
              </clipPath>
            </defs>
          </svg>
          <span class="pl-2 uppercase font-medium text-primaryBlue">
            Edit permissions
          </span>
        </div>
        <div class="pl-12 uppercase text-accentDark">Update Role</div>
      </div>
      <div
        class="mt-5 grid grid-cols-3 max-w-xl w-full bg-accentLight text-xs py-4 rounded pl-3"
      >
        <div class="text-accentDark">Cashier</div>
        <div class="flex items-center">
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M13.2839 7.03354L10.906 4.65633L11.8576 3.70545C12.2812 3.28178 13.2516 3.19772 13.7593 3.70545L14.2348 4.18089C14.7425 4.68861 14.6591 5.659 14.2348 6.08333L13.2839 7.03354ZM12.333 7.9851L5.67561 14.6426L2.58496 15.3555L3.29777 12.2648L9.95518 5.60722L12.333 7.9851Z"
                fill="#253B95"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="13.7734"
                  height="18"
                  fill="white"
                  transform="translate(0.916016)"
                />
              </clipPath>
            </defs>
          </svg>
          <span class="pl-2 uppercase font-medium text-primaryBlue">
            Edit permissions
          </span>
        </div>
        <div class="pl-12 uppercase text-accentDark">Update Role</div>
      </div>
      <div
        class="mt-5 grid grid-cols-3 max-w-xl w-full bg-accentLight text-xs py-4 rounded pl-3"
      >
        <div class="text-accentDark">Admin</div>
        <div class="flex items-center">
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M13.2839 7.03354L10.906 4.65633L11.8576 3.70545C12.2812 3.28178 13.2516 3.19772 13.7593 3.70545L14.2348 4.18089C14.7425 4.68861 14.6591 5.659 14.2348 6.08333L13.2839 7.03354ZM12.333 7.9851L5.67561 14.6426L2.58496 15.3555L3.29777 12.2648L9.95518 5.60722L12.333 7.9851Z"
                fill="#253B95"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="13.7734"
                  height="18"
                  fill="white"
                  transform="translate(0.916016)"
                />
              </clipPath>
            </defs>
          </svg>
          <span class="pl-2 uppercase font-medium text-primaryBlue">
            Edit permissions
          </span>
        </div>
        <div class="pl-12 uppercase text-accentDark">Update Role</div>
      </div>
    </div>
    <div></div>
    <trac-modal size="max-w-2xl" @close="toggle = false" v-if="toggle">
      <div class="p-10">
        <div class="flex items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.68066 13.043V28.043H10.7119V21.4805V21.4805C10.7119 19.4094 12.2859 17.7305 14.2275 17.7305C16.1692 17.7305 17.7432 19.4094 17.7432 21.4805V21.4805V28.043H24.7744V13.043"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.0459 28.042H27.4131"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.23001 10.3292H1.23001C0.795736 11.2554 1.14755 12.3817 2.01581 12.8449C2.26011 12.9752 2.52952 13.0431 2.80267 13.043H25.6542C26.625 13.0433 27.4123 12.2041 27.4126 11.1685C27.4127 10.8772 27.3491 10.5898 27.2269 10.3292L23.8964 5.54297H4.56048L1.23001 10.3292Z"
              fill="#B4E6FF"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.56055 5.54199V3.66699L4.56055 3.66699C4.56055 2.63146 5.34755 1.79199 6.31836 1.79199H22.1387V1.79199C23.1095 1.79199 23.8965 2.63146 23.8965 3.66699C23.8965 3.66699 23.8965 3.66699 23.8965 3.66699V5.54199"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="pl-4 text-sm">Assign selected roles to stores</div>
        </div>
        <div class="mt-10 flex items-center">
          <div class="text-xs w-20">Manager</div>
          <div class="w-64 ml-20">
            <trac-dropdown-exteneded selector="Use Traction" />
          </div>
        </div>
        <div class="mt-10 flex items-center">
          <div class="text-xs w-20">Cashier</div>
          <div class="w-64 ml-20">
            <trac-dropdown-exteneded selector="Use Traction" />
          </div>
        </div>
        <div class="mt-10 flex items-center">
          <div class="text-xs w-20">Admin</div>
          <div class="w-64 ml-20">
            <trac-dropdown-exteneded selector="Use Traction" />
          </div>
        </div>
        <div class="mt-24 flex justify-end items-end">
          <trac-button>
            <div class="px-4">Done</div>
          </trac-button>
        </div>
      </div>
    </trac-modal> -->
    </main>
  </div>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      toggle: false,
      isLoading: false,
      showStores: false,
      role: {
        role: "",
        stores: [],
        features: null,
      },
      assignedJD: null,
      assignedFeatures: [],
      assignedStores: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.setupAssignedJD();
    await this.fetchAllStores();
    await this.fetchAllJDs();
    this.isLoading = false;
  },
  methods: {
    toggleCheck(event, feature) {
      feature.isSelected = event.checked;
    },
    toggleStoreCheck(event, store) {
      store.isSelected = event.checked;
    },
    toggleShowStores(event) {
      this.showStores = !this.showStores;
      if (!this.showStores) {
        this.role.stores = this.role.stores.map((store) => {
          return { ...store, isSelected: false };
        });
      }
    },
    async sendRole() {
      this.isLoading = true;
      const payload = {
        businessid: GET_USER_BUSINESS_ID(),
        role: this.role.role,
        stores: this.role.stores
          .filter((store) => store.isSelected)
          .map((store) => {
            return { id: store._id, name: store.name };
          }),
        features: this.role.features
          .filter((feature) => feature.isSelected)
          .map((feature) => {
            if (GET_LOCAL_DB_DATA("role-details")) {
              delete feature.isSelected;
            }
            return {
              method: feature.Method,
              name: feature.Feature,
              route: feature.Route,
            };
          }),
      };

      await this.$store.dispatch(
        GET_LOCAL_DB_DATA("role-details") ? "UPDATE_ROLE" : "CREATE_ROLE",
        payload
      );

      const res = this.$store.getters["GET_RES"];

      this.$store.commit("REQ_ROLE_RES", null);

      if (res.status) {
        this.$router.back();
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
    async fetchAllJDs() {
      await this.$store.dispatch("FETCH_ALL_JDs");
      const res = this.$store.getters["GET_ALL_JDS"];

      if (res.status) {
        this.role.features = res.data.map((feature) => {
          return {
            ...feature,
            isSelected: this.assignedFeatures.includes(feature.Feature),
          };
        }).sort((a,b) => a.Feature > b.Feature ? 1 : -1);
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.role.stores = res.data.map((store) => {
          return {
            ...store,
            isSelected: this.assignedStores.includes(store._id),
          };
        });
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
    setupAssignedJD() {
      const roleDetails = GET_LOCAL_DB_DATA("role-details");

      if (roleDetails) {
        this.role.role = roleDetails.role;
        this.assignedFeatures = roleDetails.features.map((role) => role.name);
        this.assignedStores = roleDetails.stores
          ? roleDetails.stores.map((store) => (store.id ? store.id : store._id))
          : [];

        this.showStores = this.assignedStores.length > 0;
      }
    },
  },
};
</script>

<style>
.shadow-white {
  box-shadow: 0px 4px 20px rgba(22, 120, 176, 0.12);
}
</style>
